<template>
  <div class="login-page full-height flex ai-c jc-c fd-c fgap2">
    <router-link :to="{ name: 'Login' }">
      <img alt="Prime Internacional" src="@/assets/img/logo-prime.svg">
    </router-link>
    <div class="offwhite-bg medium-border-radius pv-4 ph-6 text-center flex fd-c jc-c">
      <h1 class="mb-1 text-xg text-bold">Portal dos Clientes</h1>
      <p class="mb-3">Digite o seu email e senha para acessar!</p>

      <form class="flex fd-c fgap2" @submit.prevent="login">
        <Input
          v-model="userData.email"
          :required="true"
          className="input-login"
          label="Email"
          name="email"
          placeholder="Seu email"
          type="email"
        />

        <Input
          v-model="userData.senha"
          :error="error"
          :required="true"
          className="input-login"
          label="Senha"
          name="senha"
          placeholder="Sua senha"
          type="password"
        />

        <div class="pv-1 text-right">
          <router-link :to="{name: 'ForgotPassword'}">Esqueceu sua senha?</router-link>
        </div>

        <button class="btn solid primary full-width extra-big" type="submit">ACESSAR</button>
        <div v-if="loading">carregando</div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import Input from '@/components/DataInput/Input.vue';
import axios from 'axios';

export default {
  name: 'Login',
  components: {
    Input,
  },
  computed: {
    ...mapState(['loading']),
  },
  data() {
    return {
      userData: {
        email: '',
        senha: '',
      },
      error: new Error(),
    };
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('auth', ['updateUser']),
    async login() {
      this.toggleLoading(true);
      this.error = new Error();
      try {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', this.userData.email);
        params.append('password', this.userData.senha);

        const response = await api.post('/oauth/token', params, {
          auth: {
            username: process.env.VUE_APP_API_USER,
            password: process.env.VUE_APP_API_PWD,
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (response) {
          const userResponse = await api.get('/credential');
          await this.saveAccessLog();
          this.updateUser(userResponse.data.data);
          this.$router.push('/');
        }
      } catch (err) {
        if (err.message === 'Bad credentials') {
          this.error = new Error('Usuário ou senha inválido.');
        } else {
          this.$toast.error('Houve algum problema ao tentar realizar o login. Caso o problema continue, entre em contato conosco.');
        }
      }
      this.toggleLoading(false);
    },
    async saveAccessLog() {
      const response = await axios.get(
        'https://ipinfo.io/?token=66401ea11bcd92',
      );

      if (
        (response.data.ip !== null || response.data.ip !== '')
        && response.data.city !== null
        && response.data.region !== ''
      ) {
        try {
          const data = {
            ipAddress: response.data.ip,
            origin: `${response.data.city} - ${response.data.region} - ${response.data.country}`,
            tag: 'PORTAL_CLIENTES',
            provider: response.data.org,
          };

          await api.post('/userAccessLogs', data);
        } catch {
          console.error('Erro ao salvar log de acesso!');
        }
      } else {
        console.log('Dados principais do log não foram encontrados!');
      }
    },
  },
};
</script>

<style scoped>
.login-page {
  background: var(--primary-color);
}

form {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1300px) {
  .login-page.columns {
    grid-template-columns: 1fr;
    background: var(--white-color);
  }
}
</style>
